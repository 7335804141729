import React from 'react';

import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import ScrollToTop from '../common/ScrollToTop';
import About from './components/About';
import Contact from './components/Contact';
import DriveEval from './components/DriveEval';
import FAQPage from './components/FAQPage';
import Footer from './components/Footer';
import Main from './components/Main';
import NavBar from './components/NavBar';
import { gray, light, medium, normal, small, veryBold } from './constants';
import UnderMaintenance from './UnderMaintenance';

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '3.8vw',
      lineHeight: '4vw',
      fontWeight: veryBold,
    },
    h2: {
      fontSize: '3vw',
      lineHeight: '3.2vw',
      fontWeight: veryBold,
    },
    h3: {
      fontSize: medium,
      fontWeight: veryBold,
      color: 'white',
    },
    h4: {
      fontSize: medium,
      fontWeight: normal,
      color: 'white',
    },
    h5: {
      fontSize: small,
      fontWeight: light,
      color: 'white',
    },
    fontFamily: ['Poppins', 'Helvetica', 'Work Sans', 'Sans Serif'].join(','),
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: '#e8e8e8',
          marginBottom: '1%',
          '& label.Mui-focused': {
            color: gray,
            fontSize: '2vw',
          },
          input: {
            color: gray,
            fontSize: '2vw',
          },
          label: {
            color: gray,
            fontSize: '2vw',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
});

const WebsiteHomePage = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ScrollToTop />
    <NavBar />
    <Switch>
      <Route exact path="/" render={() => <Main />} />
      <Route path="/about" render={() => <About />} />
      <Route path="/driveeval" render={() => <DriveEval />} />
      <Route path="/faq" render={() => <FAQPage />} />
      <Route path="/contact" render={() => <Contact />} />
      <Route path="/maintenance" render={() => <UnderMaintenance />} />
    </Switch>
    <Footer />
  </ThemeProvider>
);

export default WebsiteHomePage;
