/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { Box, Grid, Typography, Button } from '@mui/material';
import { t } from 'i18next';
import { Link, NavLink } from 'react-router-dom';

import { getTranslationLabel } from '../../../../utils/utils';

interface LaunchAppProps {
  username: string;
}

const LaunchApp: React.FC<LaunchAppProps> = ({ username }) => (
  <Box
    sx={{
      border: '1px solid',
      borderColor: 'grey.400',
      padding: '1rem',
      borderRadius: '8px',
      marginBottom: '2rem',
    }}
  >
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ display: 'block', textAlign: 'center' }}>
          {getTranslationLabel('website/general', 'signedInAs', t)} {username}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1rem' }}>
        <Button
          component={NavLink}
          to="/clients"
          variant="outlined"
          color="primary"
          size="large"
        >
          {getTranslationLabel('website/general', 'launchApp', t)}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

export default LaunchApp;
