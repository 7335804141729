import React from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import ScrollToTop from './components/common/ScrollToTop';
import WebsiteHomePage from './components/website/WebsiteHomePage';
import Protected from './Protected';

const App = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <ScrollToTop />
      <CssBaseline />
      <Switch>
        <Route exact path="/">
          <WebsiteHomePage />
        </Route>
        <Route path={['/about', '/driveeval', '/faq', '/contact']}>
          <WebsiteHomePage />
        </Route>
        <Route path={['/clients', '/help', '/allocateerror', '/user/settings']}>
          {isAuthenticated ? <Protected /> : <>401: Not Authorized</>}
        </Route>
        <Route path="*">
          <>404: Page Not Found</>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
