import React, { FC, useEffect } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import allNamespaces from '../../allNamespaces';
import AsyncErrorWrapper from '../../errors/AsyncErrorWrapper';
import {
  useResults,
  useCreateResults,
  useUpdateResults,
} from '../../hooks/database/results/results';
import useToken from '../../hooks/useToken';
import i18n from '../../i18n';
import { IForm } from '../../models/forms';
import { IResult, blankResult } from '../../models/results';
import FormContext from '../../utils/formContext';
import { getTranslationLabel } from '../../utils/utils';
import { FileOpInProgress } from '../common/FileUpload/FileOpInProgressModal';
import FormSection from '../common/FormSection';
import { PaperHeader } from '../common/PaperHeader';
import RESULTS_FIELDS from './RESULTS_CONSTANTS';

export interface ResultsProps {
  clientId: string;
}

export const Results: FC<ResultsProps> = ({ clientId }) => {
  const { t } = useTranslation(allNamespaces);
  const { accounts } = useMsal();
  const { username } = accounts[0];
  const { isLoading, isError, data: resultsData } = useResults(clientId);
  const createMutation = useCreateResults(clientId);
  const updateMutation = useUpdateResults(clientId);
  const getSilentToken = useToken();

  const currentLanguage = i18n.language;

  const [isFileDownloadInProgress, setIsFileDownloadInProgress] =
    React.useState<boolean>(false);

  const { handleSubmit, register, control, watch, setValue, getValues, reset } =
    useForm();
  const resultsForm: IForm = {
    register,
    control,
    setValue,
    getValues,
    watch,
  };

  useEffect(() => {
    if (resultsData && resultsData[0]) {
      reset(resultsData[0]);
    }
  }, [reset, resultsData]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography>There was a problem getting the results.</Typography>;
  }

  const getReport = (type: string, filename: string, language: string) => {
    setIsFileDownloadInProgress(true);
    getSilentToken.then((response) => {
      const header = new Headers();
      const bearer = response.accessToken;
      header.append('Authorization', bearer);

      return fetch(`/api/report/${username}/${clientId}/${type}/${language}`, {
        headers: header,
      })
        .then((fileRecd: any) => fileRecd.blob())
        .then((resp: any) => {
          setIsFileDownloadInProgress(false);
          const fileUrl = window.URL.createObjectURL(resp);

          const linkElement = document.createElement('a');
          linkElement.href = fileUrl;
          linkElement.setAttribute('download', `${filename}-${clientId}`);
          document.body.appendChild(linkElement);
          linkElement.click();
          if (linkElement.parentNode) {
            linkElement.parentNode.removeChild(linkElement);
          }

          window.URL.revokeObjectURL(fileUrl);
        });
    });
  };

  const onSubmit = async (dataToSubmit: IResult) => {
    if (resultsData && resultsData[0]) {
      updateMutation.mutate({
        ...dataToSubmit,
        clientId,
      });
    } else {
      createMutation.mutate({
        ...blankResult,
        ...dataToSubmit,
        clientId,
      });
    }
  };

  return (
    <Grid item container direction="column" justifyContent="center" spacing={3}>
      <Grid item>
        <PaperHeader
          title={getTranslationLabel('results', 'resultsAndReportsHeading', t)}
          clientId={clientId}
          backLink="/clients"
        />
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={12} lg={6}>
          <Paper square style={{ padding: '20px' }}>
            <Grid container spacing={3}>
              <AsyncErrorWrapper mutation={updateMutation}>
                <AsyncErrorWrapper mutation={createMutation}>
                  <FormContext.Provider value={resultsForm}>
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h2">
                          {getTranslationLabel('results', 'results', t)}
                        </Typography>
                      </Grid>
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        style={{ margin: '10px 0px 0px 10px' }}
                      >
                        {getTranslationLabel('generalNs', 'save', t)}
                      </Button>
                    </Grid>
                    <FormSection
                      fields={RESULTS_FIELDS}
                      values={watch()}
                      namespace="results"
                      sectionKey="results"
                    />
                  </FormContext.Provider>
                </AsyncErrorWrapper>
              </AsyncErrorWrapper>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper square style={{ padding: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h2">Reports</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() =>
                    getReport('main', 'Report-For', currentLanguage)
                  }
                >
                  {getTranslationLabel('results', 'msWordReportButton', t)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {getTranslationLabel('results', 'ontarioSpecificHeading', t)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() =>
                    getReport('mto', 'MTO-Medical', currentLanguage)
                  }
                >
                  {getTranslationLabel(
                    'results',
                    'ontarioMedicalFormButton',
                    t
                  )}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() =>
                    getReport('mtovw', 'MTO-VisionWaiver', currentLanguage)
                  }
                >
                  {getTranslationLabel('results', 'ontarioVWFormButton', t)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {getTranslationLabel('results', 'quebecSpecificHeading', t)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() =>
                    getReport('saaq57', 'SAAQ57-Form57', currentLanguage)
                  }
                >
                  {getTranslationLabel('results', 'quebecForm57Button', t)}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <FileOpInProgress
        show={isFileDownloadInProgress}
        title={getTranslationLabel('results', 'downloadingHeading', t)}
        text={getTranslationLabel('results', 'pleaseWaitText', t)}
      />
    </Grid>
  );
};
