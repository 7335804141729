import { FieldsLayout, IOption } from '../../../models/forms';
import { getOptionsFromLabels } from './AssessmentCard-utils';

export const ASSESSMENT_PASS_KEYS = [
  'pass',
  'noConcernsForDriving',
  'functionalForDriving',
  'withinNormalLimits',
  'meetsLicensingStandards',
  '1VeryLow',
  '2Low',
  'normalRange',
  'continueDriving',
];

export const ASSESSMENT_WARN_KEYS = [
  'possibleConcernsForDriving',
  'functionalForDrivingWithAids',
  '3LowToModerate',
  'moderateDiscrepancy',
  'rescreenAfterFurtherStabilization',
  'referForSpecializedDrivingAssessment',
];

export const ASSESSMENT_FAIL_KEYS = [
  'fail',
  'seriousConcernsForDriving',
  'notFunctionalForDriving',
  'notWithinNormalLimits',
  'doesNotMeetLicensingStandards',
  '4ModerateToHigh',
  '5High',
  'severeDiscrepancy',
  'unsafeToDrive',
];

export const YES_NO: IOption[] = getOptionsFromLabels(['Yes', 'No']);

export const PASS_FAIL: IOption[] = getOptionsFromLabels(['Pass', 'Fail']);

export const TRUE_FALSE_OPTIONS: IOption[] = getOptionsFromLabels([
  'Yes',
  'No',
  'Unknown',
]);

export const PASS_FAIL_UNDETERMINED: IOption[] = getOptionsFromLabels([
  'Pass',
  'Fail',
  'Undetermined',
]);

export const RESULTS_CATEGORIES: IOption[] = getOptionsFromLabels([
  'No concerns for driving',
  'Possible concerns for driving',
  'Serious concerns for driving',
  'Undetermined',
]);

export const PHYSICAL_RESULTS_CATEGORIES: IOption[] = getOptionsFromLabels([
  'Functional for driving',
  'Functional for driving with aids',
  'Not functional for driving',
  'Undetermined',
]);

const VISUAL_RESULTS_CATEGORIES: IOption[] = getOptionsFromLabels([
  'Within normal limits',
  'Not within normal limits',
  'Undetermined',
]);

const QUALIFIER: FieldsLayout = {
  label: 'Factors likely influencing performance',
  key: 'factorsLikelyInfluencingPerformance',
  fieldType: 'TEXT-AREA',
  namespace: 'assessmentsGeneral/assessmentLabels',
};

export const Results: FieldsLayout[] = [
  {
    label: 'Overall OT interpretation',
    key: 'overallOTAssignedResult',
    fieldType: 'RADIO',
    options: RESULTS_CATEGORIES,
    namespace: 'assessmentsGeneral/assessmentLabels',
  },
  QUALIFIER,
];

export const PhysicalResults: FieldsLayout[] = [
  {
    label: 'Overall OT interpretation',
    key: 'overallOTAssignedResult',
    fieldType: 'RADIO',
    options: PHYSICAL_RESULTS_CATEGORIES,
    namespace: 'assessmentsGeneral/assessmentLabels',
  },
  QUALIFIER,
];

export const VisualResults: FieldsLayout[] = [
  {
    label: 'Overall OT assigned result',
    key: 'overallOTAssignedResult',
    fieldType: 'RADIO',
    options: VISUAL_RESULTS_CATEGORIES,
    namespace: 'assessmentsGeneral/assessmentLabels',
  },
  QUALIFIER,
];

export const RawScoreAnalysis: FieldsLayout = {
  label: 'Analysis of the raw score',
  key: 'analysisOfTheRawScore',
  fieldType: 'RADIO',
  options: PASS_FAIL,
  namespace: 'assessmentsGeneral/assessmentLabels',
};

export const PassFailResults: FieldsLayout[] = [RawScoreAnalysis, QUALIFIER];

type KeyToResultDictionary = {
  [key: string]: string;
};

const keysToResultLabels: KeyToResultDictionary = {
  noConcernsForDriving: 'noConcerns', // cognitive
  possibleConcernsForDriving: 'possibleConcern',
  seriousConcernsForDriving: 'seriousConcern',
  functionalForDriving: 'functional', // physical
  notFunctionalForDriving: 'notFunctional',
  functionalForDrivingWithAids: 'functionalWithAids',
  doesNotMeetLicensingStandards: 'doesNotMeetStandards', // vision
  meetsLicensingStandards: 'meetsStandards',
  pass: 'pass', // pass fail
  fail: 'fail',
  '1VeryLow': 'veryLow', // ufov
  '2Low': 'low',
  '3LowToModerate': 'lowToModerate',
  '4ModerateToHigh': 'moderateToHigh',
  '5High': 'high',
  normalRange: 'normalRange', // QNST
  moderateDiscrepancy: 'moderateDiscrepancy',
  severeDiscrepancy: 'severeDiscrepancy',
  continueDriving: 'continueDriving', // Sask-OT
  unsafeToDrive: 'unsafeToDrive',
  rescreenAfterFurtherStabilization: 'reScreen',
  referForSpecializedDrivingAssessment: 'referToSpecialist',
  withinNormalLimits: 'wNL', // ?
  notWithinNormalLimits: 'nWNL',
};

export const getKeysToResultLabels = () =>
  Object.keys(keysToResultLabels).map((key) => ({
    key,
    resultLabel: keysToResultLabels[key],
  }));

export const OfficialClientsPerspective: string[] = [
  '',
  "Client's perspective",
  'Official perspective',
];

export const SupportPersonClientsPerspective: string[] = [
  '',
  "Client's perspective",
  "Support person's perspective",
];

export const getLabelText = (input: string | undefined) =>
  getKeysToResultLabels().find((element) => element.key === input)?.resultLabel;
