import React, { useState } from 'react';

import { EventType, IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CallIcon from '@mui/icons-material/Call';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import LanguageIcon from '@mui/icons-material/Language';
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Link,
  Toolbar,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';

import { websiteNamespaces } from '../../../allNamespaces';
import { loginRequest } from '../../../auth/authConfig';
import i18n from '../../../i18n';
import {
  fetchPlus,
  getTranslationLabel,
  languageAbbr,
} from '../../../utils/utils';
import { bold, containerWidth, gray, darkBlue } from '../constants';
import logo from '../img/logo-streetsure.png';
import menu from '../img/menu-icon.png';
import LaunchApp from './Helpers/LaunchApp';
import NavListDrawer from './Helpers/NavListDrawer';

const navArrayLinks = [
  {
    key: 'about',
    title: 'About',
    path: '/about',
    onClick: undefined,
    icon: <GroupsIcon />,
  },
  {
    key: 'driveEval',
    title: 'DriveEval',
    path: '/driveeval',
    onClick: undefined,
    icon: <DriveEtaIcon />,
  },
  {
    key: 'faq',
    title: 'FAQ',
    path: '/faq',
    onClick: undefined,
    icon: <HelpIcon />,
  },
  {
    key: 'contact',
    title: 'Contact',
    path: '/contact',
    onClick: undefined,
    icon: <CallIcon />,
  },
  {
    key: 'enfr',
    title: 'EN/FR',
    path: undefined,
    onClick: () => i18n.changeLanguage(languageAbbr()),
    icon: <LanguageIcon />,
  },
];

const NavBar = () => {
  const { t } = useTranslation(websiteNamespaces);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const signInClickHandler = (instance: IPublicClientApplication) => {
    instance.loginRedirect(loginRequest);
  };

  const signOutClickHandler = (instance: IPublicClientApplication) => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  const NUMBER_OF_RETRIES = 7;

  const { instance, accounts } = useMsal();

  instance.addEventCallback((message: { eventType: any }) => {
    if (accounts.length > 0 && message.eventType === EventType.LOGIN_SUCCESS) {
      const { username } = accounts[0];
      const fetchOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: username,
          objectId: accounts[0].localAccountId,
        }),
      };

      fetch('/api/init/checkallocate', fetchOptions)
        .then((resp: Response) => {
          if (resp.status === 200) {
            return 200;
          }

          return fetchPlus(
            '/api/init/allocate',
            fetchOptions,
            NUMBER_OF_RETRIES
          ).status.toNumber();
        })
        .then((respStatus: number) => {
          if (respStatus === 500) {
            history.push('/allocateerror');
          }
        });
    }
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        width: `${containerWidth + 5}%`,
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{
          padding: '20px 0px 20px 0px',
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                {/* Hamburger Menu */}
                <Grid item>
                  <Box
                    component="img"
                    sx={{
                      maxWidth: '4vw',
                      minWidth: '40px',
                      height: 'auto',
                      display: { xs: 'block', xl: 'none' },
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    alt="Menu"
                    src={menu}
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </Grid>

                {/* Logo (large) */}
                <Grid item sx={{ display: { xs: 'none', xl: 'block' } }}>
                  <Link href="/" sx={{ marginLeft: '10px' }}>
                    <Box
                      component="img"
                      sx={{
                        maxWidth: '13.5vw',
                        minWidth: '140px',
                        height: 'auto',
                      }}
                      alt="Streetsure logo"
                      src={logo}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            {/* Logo (mobile) */}
            <Grid item sx={{ display: { xs: 'block', xl: 'none' } }}>
              <Link href="/" sx={{ marginLeft: '1vw' }}>
                <Box
                  component="img"
                  sx={{
                    maxWidth: '13.5vw', // 260px
                    minWidth: '125px',
                    height: 'auto', // 80px
                  }}
                  alt="Streetsure logo"
                  src={logo}
                />
              </Link>
            </Grid>

            {/* Navbar */}
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
                    {navArrayLinks.map((item) => (
                      <Link
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '1.5vw',
                          fontWeight: bold,
                          color: gray,
                          textTransform: 'none',
                          marginLeft: '50px',
                        }}
                        key={item.key}
                        component={NavLink}
                        to={item.path ? item.path : {}}
                        onClick={item.onClick}
                      >
                        {getTranslationLabel('website/general', item.key, t)}
                      </Link>
                    ))}
                  </Box>
                </Grid>

                {/* Sign in */}
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: { darkBlue },
                      borderRadius: '40px',
                      fontFamily: 'Poppins',
                      fontSize: 'max(14px, 1.5vw)',
                      fontWeight: bold,
                      marginLeft: '2.5vw',
                      height: '4vw',
                      minWidth: '10vw',
                      minHeight: '50px',
                      textTransform: 'none',
                      ':hover': {
                        bgcolor: { darkBlue },
                      },
                    }}
                    onClick={
                      isAuthenticated
                        ? () => signOutClickHandler(instance)
                        : () => signInClickHandler(instance)
                    }
                  >
                    {getTranslationLabel(
                      'website/general',
                      isAuthenticated ? 'signOut' : 'signIn',
                      t
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open}
        anchor="left"
        onClose={() => setOpen(false)}
        sx={{ display: { xs: 'flex', xl: 'none' } }}
      >
        <NavListDrawer
          navArrayLinks={navArrayLinks}
          NavLink={NavLink}
          setOpen={setOpen}
        />
      </Drawer>

      {isAuthenticated && <LaunchApp username={accounts[0].username} />}
    </Container>
  );
};
export default NavBar;
